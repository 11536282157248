import { gql } from '@apollo/client'

export const OPENING_HOURS = gql`
    query {
        openingHours {
          monday {
            openingTime
            closingTime
            isClosed
          }
          tuesday {
            openingTime
            closingTime
            isClosed
          }
          wednesday {
            openingTime
            closingTime
            isClosed
          }
          thursday {
            openingTime
            closingTime
            isClosed
          }
          friday {
            openingTime
            closingTime
            isClosed
          }
          saturday {
            openingTime
            closingTime
            isClosed
          }
          sunday {
            openingTime
            closingTime
            isClosed
          }
        }
    }
`

export const UPDATE_OPENING_HOURS = gql`
    mutation($openingHours: OpeningHoursInput!) {
        updateOpeningHours(openingHours: $openingHours) {
            monday {
                openingTime
                closingTime
                isClosed
            }
            tuesday {
                openingTime
                closingTime
                isClosed
            }
            wednesday {
                openingTime
                closingTime
                isClosed
            }
            thursday {
                openingTime
                closingTime
                isClosed
            }
            friday {
                openingTime
                closingTime
                isClosed
            }
        }
    }
`
