/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect } from 'react'
import { styled } from '@mui/material/styles';
import type { FC, ReactNode } from 'react'
import {
  useLocation, matchPath, Link as RouterLink
} from 'react-router-dom'
import PerfectScrollbar from 'react-perfect-scrollbar'
import PropTypes from 'prop-types'
import {
  Box,
  Divider,
  Drawer,
  Hidden,
  List,
  Theme,
} from '@mui/material'
import {
  Menu as MenuIcon,
  User as UserIcon,
  Smile as SmileIcon,
  Heart as HeartIcon,
  Lock as LockIcon,
  Clock as CLockIcon,
  DollarSign as DollarIcon,
  Settings as SettingsIcon,
  Icon,
} from 'react-feather'
import { Dashboard as DashboardIcon } from '@mui/icons-material'
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import useAuth from 'src/hooks/useAuth'
import Logo from 'src/components/Logo'
import { getToken } from 'src/utils/auth'
import { gql, useQuery } from '@apollo/client'
import NavItem from './NavItem'

const PREFIX = 'NavBar';

const classes = {
  mobileDrawer: `${PREFIX}-mobileDrawer`,
  desktopDrawer: `${PREFIX}-desktopDrawer`,
  avatar: `${PREFIX}-avatar`,
  bgColor: `${PREFIX}-bgColor`
};

// TODO jss-to-styled codemod: The Fragment root was replaced by div. Change the tag if needed.
const Root = styled('div')((
  {
    theme
  }: {
    theme: Theme & { palette: { gray: { [key: number]: string } } }
  }
) => ({
  [`& .${classes.mobileDrawer}`]: {
    width: 256,
  },

  [`& .${classes.desktopDrawer}`]: {
    width: 256,
    height: 'calc(100%)',
    backgroundColor: '#330662'
  },

  [`& .${classes.avatar}`]: {
    cursor: 'pointer',
    width: 64,
    height: 64
  },

  [`& .${classes.bgColor}`]: {
    backgroundColor: theme.palette.secondary.main,
    minHeight: 64,
  }
}));

interface NavBarProps {
  onMobileClose: () => void,
  openMobile: boolean
}

interface Item {
  href?: string,
  icon?: ReactNode | Icon,
  info?: ReactNode,
  items?: Item[],
  title: string,
  onClick?: () => void
}

interface Section {
  items: Item[],
  subheader: string
}

function renderNavItems({
  items,
  pathname,
  depth = 0
}: {
  items: Item[],
  pathname: string,
  depth?: number
}) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({
          acc,
          item,
          pathname,
          depth
        }),
        []
      )}
    </List>
  )
}

function reduceChildRoutes({
  acc,
  pathname,
  item,
  depth
}: {
  acc: any[],
  pathname: string,
  item: Item,
  depth: number
}) {
  const key = item.title + depth

  if (item.items) {
    const open = matchPath(pathname, {
      path: item.href,
      exact: false
    })

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={Boolean(open)}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items
        })}
      </NavItem>
    )
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        onClick={item?.onClick}
        title={item.title}
      />
    )
  }

  return acc
}

const NavBar: FC<NavBarProps> = ({ onMobileClose, openMobile }) => {
  const location = useLocation()
  const query = useQuery<{ getAPIUrl: string }>(gql`{getAPIUrl}`)
  const { user } = useAuth()
  const sections: Section[] = [
    {
      subheader: 'Application',
      items: [
        {
          title: 'Dashboard',
          icon: DashboardIcon,
          href: '/'
        },
        {
          title: 'Employees',
          icon: UserIcon,
          href: '/employees'
        },
        {
          title: 'Patients',
          icon: SmileIcon,
          href: '/patients'
        },
        {
          title: 'Caregivers',
          icon: HeartIcon,
          href: '/caregivers'
        }
      ]
    },
    {
      subheader: 'Managements',
      items: [
        {
          title: 'Requests',
          icon: HourglassEmptyIcon,
          href: '/requests'
        },
        {
          title: 'Rule Groups',
          icon: LockIcon,
          href: '/rulegroups'
        },
        {
          title: 'Time & Attendance',
          icon: CLockIcon,
          href: '/timeandattendance'
        },
        {
          title: 'Payroll',
          icon: DollarIcon,
          href: '/payrolls'
        },
        {
          title: 'Billing',
          icon: MenuIcon,
          href: '/claims'
        },
      ]
    },
  ]
  const sectionsAdmin: Section[] = [
    {
      subheader: 'Settings',
      items: [
        {
          title: 'Settings',
          icon: SettingsIcon,
          items: [
            {
              title: 'Salesforce Integration',
              href: '/settings',
            },
            {
              title: 'Job Queues (preview)',
              onClick: async () => {
                const host = query.data?.getAPIUrl
                const token = getToken()

                window.open(`${host}/admin/queues?token=${token}`, '_blank');
              }
            },
            {
              title: 'Chat',
              href: '/chatsettings',
            },
          ]
        }
      ]
    }
  ]

  const sectionsToUse = user?.role === 'system admin' ? [...sections, ...sectionsAdmin] : sections

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose()
    }
  }, [location.pathname])

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar
        options={{
          suppressScrollX: true
        }}
      >
        <Box sx={{ bgcolor: '#6642b5' }} className={classes.bgColor} display="flex" justifyContent="start" alignItems="center">
          <RouterLink to="/">
            <Logo style={{ marginLeft: '24px' }} />
          </RouterLink>
        </Box>
        <Box p={2} sx={{ bgcolor: '#330662' }}>
          {sectionsToUse.map((section, i) => (
            <>
              <List
                style={{ marginTop: 10, marginBottom: 10 }}
                key={section.subheader}
              >
                {renderNavItems({
                  items: section.items,
                  pathname: location.pathname
                })}
              </List>
              {i + 1 < sectionsToUse.length && <Divider sx={{ bgcolor: '#57008F', borderWidth: 1 }} />}
            </>
          ))}
        </Box>
        <Divider />
      </PerfectScrollbar>
    </Box>
  )

  return (
    <Root>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{
            paper: classes.mobileDrawer
          }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden lgDown>
        <Drawer
          anchor="left"
          classes={{
            paper: classes.desktopDrawer
          }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </Root>
  );
}

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
}

export default NavBar
