import React, { useRef, useState } from 'react';
import { styled } from '@mui/material/styles';
import type { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import {
  Avatar, Box, ButtonBase, Menu, MenuItem, Typography, useMediaQuery
} from '@mui/material';
import useAuth from 'src/hooks/useAuth';

const PREFIX = 'Account';

const classes = {
  avatar: `${PREFIX}-avatar`,
  popover: `${PREFIX}-popover`,
  addColor: `${PREFIX}-addColor`,
};

const Root = styled('div')(({ theme }) => ({
  [`& .${classes.avatar}`]: {
    height: 32,
    width: 32,
    marginRight: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
    color: '#eeebff',
  },
  [`& .${classes.popover}`]: {
    width: 200,
  },
  [`& .${classes.addColor}`]: {
    color: theme.palette.primary.main,
  },
}));

const Account: FC = () => {
  const ref = useRef<HTMLDivElement | null>(null);
  const { enqueueSnackbar } = useSnackbar();
  const [isOpen, setOpen] = useState<boolean>(false);
  const { logout } = useAuth();
  const isMdUp = useMediaQuery((theme: any) => theme.breakpoints.up('md'));

  const handleOpen = (): void => {
    setOpen(true);
  };

  const handleClose = (): void => {
    setOpen(false);
  };

  const handleLogout = async (): Promise<void> => {
    try {
      logout();
    } catch (err) {
      console.error(err);
      enqueueSnackbar('Unable to logout', {
        variant: 'error',
      });
    }
  };

  return (
    <Root>
      <Box
        display="flex"
        alignItems="center"
        component={ButtonBase}
        onClick={handleOpen}
        pl={1}
        ref={ref}
      >
        <Avatar alt="User" className={classes.avatar} />
        {isMdUp && <Typography variant="h6" color="inherit" />}
      </Box>
      <Menu
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        keepMounted
        PaperProps={{
          className: classes.popover,
        }}
        anchorEl={ref.current}
        open={isOpen}
      >
        <MenuItem component={RouterLink} to="/">
          Profile
        </MenuItem>
        <MenuItem onClick={handleLogout}>Logout</MenuItem>
      </Menu>
    </Root>
  );
};

export default Account;
