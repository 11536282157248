import React, { useRef, useMemo } from 'react';
import { styled } from '@mui/material/styles';
import clsx from 'clsx';
import {
  AppBar,
  Box,
  IconButton,
  Toolbar,
  SvgIcon,
  Typography,
  useMediaQuery,
  Theme,
  MenuItem,
  Menu,
} from '@mui/material';
import { Add } from '@mui/icons-material';
import CreateClockModal from 'src/components/CreateClockModal';
import TimeSheetExportModal from 'src/components/TimeSheetExportModal';
import { Menu as MenuIcon } from 'react-feather';
import CompTimeModal from 'src/components/CompTimeModal';
import { useQuery } from '@apollo/client';
import { SFCAREGIVER } from 'src/apollo/queries';
import { SfCaregiver } from 'src/types/__generated__/typescript-operations';
import Settings from './Settings';
import Account from './Account';
import Search from './Search';
import Chat from './Chat';

const PREFIX = 'TopBar';

const classes = {
  root: `${PREFIX}-root`,
  toolbar: `${PREFIX}-toolbar`,
  addColor: `${PREFIX}-addColor`,
};

const getTitle = (url: string) => {
  if (!url) return 'Welcome Back!';
  const titles: Record<string, string> = {
    timeandattendance: 'TIME AND ATTENDANCE',
    chatsettings: 'CHAT SETTINGS',
    clocksearch: 'CLOCK SEARCH',
  };
  return titles[url] || url.toUpperCase();
};

const StyledAppBar = styled(AppBar)(({ theme }: { theme: Theme }) => ({
  [`&.${classes.root}`]: {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
    boxShadow: 'none',
  },
  [`& .${classes.toolbar}`]: {
    justifyContent: 'space-between',
    minHeight: 64,
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  [`& .${classes.addColor}`]: {
    color: theme.palette.primary.main,
  },
}));

interface TopBarProps {
  className?: string;
  onMobileNavOpen?: () => void;
}

const TopBar: React.FC<TopBarProps> = ({ className, onMobileNavOpen, ...rest }) => {
  const [, url, id] = window.location.pathname?.split('/') || [];
  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
  const [openClockModal, setOpenClockModal] = React.useState(false);
  const [openPtoModal, setOpenPtoModal] = React.useState(false);
  const [openMenu, setOpenMenu] = React.useState(false);
  const formattedUrl = url?.toUpperCase();
  const isPatient = formattedUrl === 'PATIENT';
  const isCaregiver = formattedUrl === 'CAREGIVER';
  const ref = useRef<HTMLDivElement | null>(null);

  const { data: sfCaregiverData } = useQuery<{ sfCaregiver: SfCaregiver }>(SFCAREGIVER, {
    variables: { id },
    skip: !isCaregiver,
    fetchPolicy: 'no-cache',
  });

  const formattedCaregiver = useMemo(() => {
    if (!sfCaregiverData) return undefined;
    return {
      ...sfCaregiverData.sfCaregiver.caregiver,
      sfContact: sfCaregiverData.sfCaregiver,
    };
  }, [sfCaregiverData]);

  return (
    <StyledAppBar className={clsx(classes.root, className)} {...rest} position="sticky">
      <Toolbar className={classes.toolbar}>
        {!lgUp && (
          <IconButton color="inherit" onClick={onMobileNavOpen} size="large">
            <SvgIcon fontSize="small">
              <MenuIcon />
            </SvgIcon>
          </IconButton>
        )}

        <Box>
          {lgUp && <Typography variant="h4">{getTitle(url)}</Typography>}
        </Box>

        <Box display="flex" alignItems="center">
          {(formattedUrl === 'CAREGIVER' || formattedUrl === 'PATIENT') && (
            <TimeSheetExportModal id={id} type={formattedUrl} />
          )}
          <Box display="flex" alignItems="center">
            <div ref={ref}>
              <Add
                className={classes.addColor}
                onClick={() => setOpenMenu(true)}
                style={{ cursor: 'pointer', marginRight: 5 }}
              />
            </div>

            <Menu
              onClose={() => setOpenMenu(false)}
              anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
              keepMounted
              anchorEl={ref.current}
              open={openMenu}
            >
              <MenuItem
                onClick={() => {
                  setOpenClockModal(true);
                  setOpenMenu(false);
                }}
              >
                Add Clock
              </MenuItem>
              <MenuItem
                onClick={() => {
                  setOpenPtoModal(true);
                  setOpenMenu(false);
                }}
              >
                Add PTO
              </MenuItem>
            </Menu>
          </Box>
          <Search />
          <Settings />
          <Chat />
          <Account />
        </Box>
      </Toolbar>

      {openClockModal && (
        <CreateClockModal
          open={openClockModal}
          setOpen={setOpenClockModal}
          patientId={isPatient ? id : undefined}
          caregiverId={sfCaregiverData?.sfCaregiver?.caregiver.id}
        />
      )}

      {openPtoModal && (
        <CompTimeModal
          open={openPtoModal}
          setOpen={setOpenPtoModal}
          caregiver={isCaregiver ? formattedCaregiver : undefined}
        />
      )}
    </StyledAppBar>
  );
};

export default TopBar;
