import React, {
  useState,
  useRef
} from 'react';
import {
  Box,
  Button,
  Popover,
  Tooltip,
} from '@mui/material';
import { CsvBuilder } from 'filefy'
import {
  PDFDownloadLink,
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image
} from '@react-pdf/renderer';
import { sortBy } from 'ramda';
import {
  endOfWeek,
  format,
  startOfWeek,
  subWeeks
} from 'date-fns';
import { GetAppOutlined as FileDownload, } from '@mui/icons-material'
import { CLOCKS } from 'src/apollo/queries';
import { useQuery } from '@apollo/client';
import { Clock } from 'src/types/__generated__/typescript-operations';
import DateRangeComponent from './DateRangeComponent';

interface TimeSheetExportModalProps {
  id: string,
  type: 'CAREGIVER' | 'PATIENT'
}

const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  table: {
    width: '100%',
    border: '1px solid #000',
  },
  tableRow: {
    flexDirection: 'row',
  },
  tableColHeader: {
    width: '10%',
    padding: 1,
    fontSize: 5,
    borderBottom: '1px solid #000',
  },
  tableColHeaderSmall: {
    width: '5%',
    padding: 1,
    fontSize: 5,
    borderBottom: '1px solid #000',
  },
  tableCol: {
    width: '10%',
    borderStyle: 'solid',
    borderBottomWidth: 1,
    borderBottomColor: '#bfbfbf',
    padding: 1,
    fontSize: 5,
  },
  tableColSmall: {
    width: '5%',
    borderStyle: 'solid',
    borderBottomWidth: 1,
    borderBottomColor: '#bfbfbf',
    padding: 1,
    fontSize: 5,
  },
  customImage: {
    height: '28px',
    width: '88px',
    marginBottom: '8px',
  },
  customStartDateEndDate: {
    fontSize: '10px',
    fontWeight: 'bold',
    marginTop: '-1px',
    marginBottom: '4px',
  },
  customElement: {
    fontSize: '10px',
    marginTop: '-1px',
  },
  topRightContainer: {
    position: 'absolute',
    top: 10,
    right: 0,
  },
  textWrapper: {
    fontSize: '12px',
    marginBottom: '4px',
    marginLeft: 'auto',
    fontWeight: 'bold',
  },
  textWrapper2: {
    fontSize: '10px',
    marginBottom: '3px',
    marginLeft: 'auto',
  },
  textWrapper3: {
    fontSize: '8px',
    marginBottom: '5px',
    marginLeft: 'auto',
  },
  textWrapper4: {
    fontSize: '8px',
    marginLeft: 'auto',
  },
  frame3: {
    display: 'flex',
    flexDirection: 'column',
  },
});

const TimeSheetExportModal = ({ id, type }: TimeSheetExportModalProps) => {
  const ref = useRef<any>(null);
  const [isOpen, setOpen] = useState<boolean>(false);
  const [date, setDate] = useState(({
    startDate: startOfWeek(subWeeks(new Date(), 1)),
    endDate: endOfWeek(subWeeks(new Date(), 1))
  }))

  const isPatient = type === 'PATIENT'
  const isCaregiver = type === 'CAREGIVER'

  const { data: clocks, loading } = useQuery<{ clocks: Clock[] }>(CLOCKS, {
    variables: {
      data: {
        patientId: isPatient ? id : undefined,
        caregiverId: isCaregiver ? id : undefined,
        dateFrom: date?.startDate || new Date(),
        dateTo: date?.endDate || new Date()
      }
    },
    fetchPolicy: 'no-cache'
  })
  const firstName = isPatient ? clocks?.clocks[0]?.patient?.firstName : clocks?.clocks[0]?.caregiver?.sfContact?.firstName
  const lastName = isPatient ? clocks?.clocks[0]?.patient?.lastName : clocks?.clocks[0]?.caregiver?.sfContact?.lastName
  const fileName = `TimeSheet-${firstName || ''}-${lastName || ''}-${date?.startDate?.toLocaleDateString()}-${date?.endDate?.toLocaleDateString()}`
  const headers = ['Date Worked', 'Patient', 'Caregiver', 'Clock In', 'Clock Out', 'Clock In Selfie', 'Clock Out Selfie', 'Clock In Geo', 'Clock Out Geo', 'Hours Paid', 'Approved By', 'Reason', 'Note'];
  const values = clocks?.clocks?.map((clock) => {
    const latestUpdatedFlag = sortBy((flag) => flag.statusUpdatedAt, clock.flags)[0]
    const results: any = [
      format(new Date(clock.clockIn), 'MM-dd-yyyy'),
      `${clock.patient?.firstName} ${clock.patient?.lastName}`,
      `${clock.caregiver?.sfContact?.firstName} ${clock.caregiver?.sfContact?.lastName}`,
      format(new Date(clock.clockIn), 'MM-dd-yyyy h:mm aa'),
      format(new Date(clock.clockOut), 'MM-dd-yyyy h:mm aa'),
      clock.selfiePath ? 'yes' : 'no',
      clock.selfieClockOutPath ? 'yes' : 'no',
      clock.geolocation ? `latitude: ${clock.geolocation.latitude}, longitude: ${clock.geolocation.longitude}` : '',
      clock.clockOutGeolocation ? `latitude: ${clock.clockOutGeolocation.latitude}, longitude: ${clock.clockOutGeolocation.longitude}` : '',
      clock.estimatedPay,
      `${latestUpdatedFlag?.statusUpdatedBy?.firstName || ''} ${latestUpdatedFlag?.statusUpdatedBy?.lastName || ''}`,
      latestUpdatedFlag?.reason,
      latestUpdatedFlag?.note,
    ]
    return results;
  });

  const PDFDocument = () => (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.section}>
          <View style={styles.topRightContainer}>
            <Text style={styles.textWrapper}>Timesheet</Text>
            <Text style={styles.textWrapper3}>Link Homecare</Text>
            <Text style={styles.textWrapper3}>103-15 101st st. Ozone Park, NY 11417</Text>
            <Text style={styles.textWrapper2}>
              Report Generated:
              {'  '}
              {format(new Date(), 'MM-dd-yyyy')}
            </Text>
          </View>
          <View>
            <Image style={styles.customImage} src="/static/images/newLogo.png" />
            <Text style={styles.customStartDateEndDate}>
              Start Date:
              {'  '}
              {format(new Date(date?.startDate), 'MM-dd-yyyy')}
            </Text>
            <Text style={styles.customStartDateEndDate}>
              End Date:
              {'  '}
              {format(new Date(date?.endDate), 'MM-dd-yyyy')}
            </Text>
          </View>
          <View style={styles.table}>
            <View style={styles.tableRow}>
              {headers.map((header, index) => (
                <Text key={index} style={[5, 6, 7, 8, 9].includes(index) ? styles.tableColHeaderSmall : styles.tableColHeader}>
                  {header}
                </Text>
              ))}
            </View>
            {values.map((item, index) => (
              <View
                key={index}
                style={[
                  styles.tableRow,
                  index % 2 === 0 ? { backgroundColor: '#F3F3F3' } : { backgroundColor: '#DEDEDE', },
                ]}
              >
                {item.map((value, index) => (
                  <Text key={index} style={[5, 6, 7, 8, 9].includes(index) ? styles.tableColSmall : styles.tableCol}>
                    {value?.toString()}
                  </Text>
                ))}
              </View>
            ))}
          </View>
        </View>
      </Page>
    </Document>
  );

  const downloadCSV = async () => {
    new CsvBuilder(fileName)
      .setColumns(headers)
      .addRows(values)
      .exportFile()
  }

  return (
    <>
      <Tooltip title="Timesheet export">
        <Button onClick={() => setOpen(true)} ref={ref}>
          <FileDownload
            fontSize="medium"
            style={{
              cursor: 'pointer',
            }}
          />
        </Button>
      </Tooltip>
      <Popover
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        anchorEl={ref.current}
        onClose={() => setOpen(false)}
        open={isOpen}
      >
        <Box>
          <Box
            mt={2}
            px={1}
          >
            <DateRangeComponent
              timePeriodOptions={[]}
              selectionRange={date || { startDate: null, endDate: null }}
              allowClearable
              placeholder="Select date / range"
              style={{ margin: 0 }}
              setSelectionRange={(value) => setDate(value)}
              onTimePeriodChange={(e, value) => setDate(value)}
            />
          </Box>
          {loading ? <i>Loading...</i> : (
            <>
              <Box
                display="flex"
                mt={2}
                px={1}
                style={{ gap: '40px' }}
                marginBottom={2}
              >
                <Button
                  color="primary"
                  variant="contained"
                  onClick={downloadCSV}
                >
                  Export CSV
                </Button>
                <Button
                  color="primary"
                  variant="contained"
                >
                  <PDFDownloadLink document={<PDFDocument />} fileName={fileName} style={{ color: 'white', textDecoration: 'none' }}>
                    {/* @ts-ignore */}
                    {({ loading }) => {
                      if (loading) return 'Loading document...'
                      return 'Export PDF';
                    }}
                  </PDFDownloadLink>
                </Button>

              </Box>
            </>
          )}
        </Box>
      </Popover>
    </>
  );
}

export default TimeSheetExportModal;
